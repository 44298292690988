import React from 'react';
import Button from 'antd/lib/button';
import { Link } from 'gatsby';
import Section from '@components/Section';

const CATEGORIES = [
  { name: 'Job Searching', path: 'results' },
  { name: 'Professional Development', path: 'results' },
  { name: 'Career Paths', path: 'results' },
  { name: 'At the Workplace', path: 'results' },
  { name: 'Salary & Benefits', path: 'results' },
  { name: 'Personal Development', path: 'results' },
];

const BrowseCategory = ({ categories = CATEGORIES, className = '' }) => {
  return (
    <Section className={'browse-category-section'}>
      <h1 className="browse-category-header _section-label">Browse category</h1>
      <div className="button-link-collection-container">
        {categories.map((category, index) => {
          return (
            <div className="button-link-container" key={index}>
              <Link to={category.path}>
                <Button>
                  <div className="button-link-label">{category.name}</div>
                </Button>
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default BrowseCategory;

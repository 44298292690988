import React from 'react';
import isEmpty from 'lodash/isEmpty';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import BrowseCategory from '@components/BrowseCategory';
import MainPageSearch from '@components/SearchWithCTA/MainPageSearch';
import CardCarousel from '@components/CardCarousel';
import Section from '@components/Section';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import { navigate } from 'gatsby';
import { stringify } from 'query-string';
import { encodeQueryParams, StringParam, ArrayParam } from 'use-query-params';
import { URL_RESULTS_PATH } from '../helpers/constants';

import {
  CARD_CAROUSEL_TITLE,
  SEO_TITLE,
  SEARCH_HEADER,
  SEARCH_SUB_HEADER,
  SEARCH_PLACEHOLDER,
} from './helpers/constants';

const CareerAdvice = ({ data }) => {
  const {
    categoryPaths,
    carouselCardItems,
    flexSearchOptions,
    searchSuggestions,
  } = data;

  const currentUrlPath = useCurrentPath();
  const carouselStyle =
    carouselCardItems?.length === 1 && 'single-card-wrapper';

  const handleSearchQuery = (response) => {
    const { query, results } = response;
    const encodedQuery = encodeQueryParams(
      { query: StringParam, search: ArrayParam },
      { query, search: results }
    );
    const urlPath = `${currentUrlPath}${URL_RESULTS_PATH}`;
    const urlQueryParams = `?${stringify(encodedQuery)}`;
    navigate(`${urlPath}${urlQueryParams}`);
  };

  return (
    <Layout>
      <SEO title={SEO_TITLE} />
      <MainPageSearch
        header={SEARCH_HEADER}
        subHeader={SEARCH_SUB_HEADER}
        searchPlaceholder={SEARCH_PLACEHOLDER}
        onQuery={handleSearchQuery}
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
      />
      <div className="career-advice-wrapper">
        <BrowseCategory categories={categoryPaths} />
        <Section isVisible={!isEmpty(carouselCardItems)}>
          <CardCarousel
            className={carouselStyle}
            items={carouselCardItems}
            title={CARD_CAROUSEL_TITLE}
            linkRoot={currentUrlPath}
          />
        </Section>
      </div>
    </Layout>
  );
};

export default CareerAdvice;

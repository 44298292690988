import { useStaticQuery, graphql } from 'gatsby';
import { encodeQueryParams, StringParam } from 'use-query-params';
import { stringify } from 'query-string';
import { URL_RESULTS_PATH } from '../helpers/constants';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import getCareerAdviceCardsProps from '@helpers/methods/modifyContents/getCareerAdviceCardsProps';
import sanitizeArray from '@helpers/methods/modifyContents/utils/sanitizeArray';

const GET_CARRER_ADVICE_MAIN = graphql`
  {
    allStrapiCareerAdviceCategories(sort: { fields: sort, order: ASC }) {
      nodes {
        name
      }
    }

    allStrapiCareerAdvices(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: { content_status: { value: { eq: "2" } } }
    ) {
      nodes {
        strapiId
        title
        slug
        created_at(formatString: "MMM DD, YYYY")
        content
        category: career_advice_category {
          name
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }

        creator_id
        created_by {
          username
        }

        published_date
        published_at
      }
    }

    allTitleSuggestions: allStrapiCareerAdvices(
      sort: { fields: created_at, order: DESC }
      filter: { content_status: { value: { eq: "2" } } }
    ) {
      nodes {
        title
      }
    }

    localSearchCareerAdvices {
      index
      store
    }

    flexSuggestionOptions: localSearchCareerAdvicesSuggestions {
      index
    }
  }
`;

const getCategoryNames = (categories = []) =>
  categories.map((category) => category?.name);

const encodeCategoryURL = (category = '', rootPath = '') => {
  const encodedCategory = encodeQueryParams(
    { category: StringParam },
    { category: category }
  );
  const urlPath = `${rootPath}${URL_RESULTS_PATH}`;
  const urlQueryParams = `?${stringify(encodedCategory)}`;

  return `${urlPath}${urlQueryParams}`;
};

const generateCategoryPaths = (categories = [], rootPath = '') =>
  categories.map((category) => {
    return {
      name: category,
      path: encodeCategoryURL(category, rootPath),
    };
  });

const Fetcher = ({ children }) => {
  const {
    allStrapiCareerAdviceCategories,
    allStrapiCareerAdvices,
    localSearchCareerAdvices,
    flexSuggestionOptions,
    allTitleSuggestions,
  } = useStaticQuery(GET_CARRER_ADVICE_MAIN);

  const currentUrlPath = useCurrentPath();

  const categoryNames = getCategoryNames(allStrapiCareerAdviceCategories.nodes);
  const sanitizedCategoryNames = sanitizeArray(categoryNames);
  const categoryPaths = generateCategoryPaths(
    sanitizedCategoryNames,
    currentUrlPath
  );

  const carouselCardItems = getCareerAdviceCardsProps(
    allStrapiCareerAdvices.nodes
  );

  const flexSearchOptions = localSearchCareerAdvices;

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );

  const suggestedTitlesIndex = allTitleSuggestions.nodes.map(
    ({ title }) => title
  );

  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    categoryPaths,
    carouselCardItems,
    flexSearchOptions,
    searchSuggestions,
  });
};

export default Fetcher;
